export default class PartstogoScrollspy {
    constructor(el) {
        this.$el = el;
        this.$sections = document.querySelectorAll("#content > .scrollspy-target");
        this.highlightScrollspy();
    }

    highlightScrollspy() {
        const sectionsArray = gsap.utils.toArray(this.$sections);

        if (this.$sections.length) {
            const buttons = sectionsArray.map(s => document.querySelector(`.scrollspy a[href="#${s.id}"]`));

            sectionsArray.forEach((section, index) => {
                const button = buttons[index];
                ScrollTrigger.create({
                    trigger: section,
                    start: `top 50%`,
                    end: "bottom 50%",
                    onEnter: () => this.updateButtonState(button, true),
                    onLeave: () => this.updateButtonState(button, false),
                    onEnterBack: () => this.updateButtonState(button, true),
                    onLeaveBack: () => this.updateButtonState(button, false),
                });
            });
        }
    }

    updateButtonState(button, isActive) {
        if (button) {
            button.classList.toggle('active', isActive);
        }
    }
}
