class TestimonialsSwiper {

	constructor(c) {
		this.init(c);
	}

	init(container) {
		new Swiper(container.querySelector('.swiper-testimonials'), {
			autoplay: {
				delay: 6000,
			},
			effect: "creative",
			creativeEffect: {
				prev: {
					shadow: false,
					translate: ["-20%", 0, -1],
					opacity: 0
				},
				next: {
					translate: ["100%", 0, 0],
					opacity: 0,
				},
			},
			pagination: {
				el: container.querySelector(".swiper-pagination"),
				type: "fraction",
			},
			navigation: {
				nextEl: container.querySelector(".swiper-button-next"),
				prevEl: container.querySelector(".swiper-button-prev"),
			}
		});
	}
}

export default TestimonialsSwiper;