export default class ContactForm {

  constructor(c) {


    // Fetch form
    // --------------

    c.addEventListener('submit', (e, f) => {
      e.preventDefault();

      const action = c.getAttribute('action');
      const method = c.getAttribute('method');
      const body = new FormData(c);

      // for (const value of body.values()) {
      // 	console.log(value);
      // }

      // Send form values via ajax and handle results
      fetch(action, {
          method: method,
          body: body
        }).then(res => res.json())
        .then(function (data) {
          removeErrors();
          if (data.alert) {
            setErrors(data.alert);
          } else {
            showSuccess(data.success.value);
            submitFormToHubSpot(body);
          }
        });

    });



    // Take the server side validation and add errors to the input fields
    // --------------

    function setErrors(v) {

      for (const field in v) {

        // Add invalid class
        let input = c.querySelector(`#${field}`);
        if (input) input.classList.add('invalid');

        // Create and append error message
        let errorMessage = document.createElement('span');
        errorMessage.innerText = v[field];
        errorMessage.classList.add('error-message');
        input.after(errorMessage);

      }

    }



    // Clean up old error messages
    // --------------

    function removeErrors() {

      // Remove previous/old invalid classes
      let oldErrors = c.querySelectorAll('.invalid');
      oldErrors.forEach(oe => {
        oe.classList.remove('invalid');
      });

      // Remove previous/old error messages
      let oldErrorMessages = c.querySelectorAll('.error-message');
      oldErrorMessages.forEach(em => {
        em.remove();
      });

    }



    // Show success message and hide the form
    // --------------

    function showSuccess(msg) {

      // Scroll to top
      gsap.to(window, {
        duration: 0.6,
        scrollTo: {
          y: c.offsetTop - 32,
        },
        onComplete: function () {
          setClasses(msg);
        }
      });

      function setClasses(msg) {

        // Add class to form
        c.classList.add('success');

        // Activate success component
        let successDOM = c.querySelector('.success');
        successDOM.classList.add('active');

        // Set text for success message
        let msgDOM = c.querySelector('.msg');
        msgDOM.innerText = msg;
      }
    }




    // Send form data to HubSpot
    // --------------

    async function submitFormToHubSpot(formData) {

      // TODO: Hide token in .env file
      const YOUR_TOKEN = 'caf45b22-e309-47dc-8a0a-784b9812b4d4';
      const portalId = "26880219";
      const formId = "e8c51689-7e1e-4331-84c3-03a50ad2cac7";
      const proxyurl = "https://cors-anywhere.herokuapp.com/";
      const apiurl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

      // Add context data
      const dataContext = {
        "context": {
          "pageUri": window.location.href,
          "pageName": document.title
        },
      }

      // Delete unwanted helper entries (type)
      formData.delete('type');
      const data = Object.assign(convertFormDataToHubSpotSchema(formData), dataContext);
      console.log(data);

      // Send data to HubSpot
      try {
        const response = await fetch(proxyurl + apiurl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          const jsonResponse = await response.json();
          console.log(jsonResponse);
        } else {
          console.log(`Error: ${response.status}`);
          console.log(await response.text());
        }
      } catch (error) {
        console.log(error);
      }
    }



    // Convert FormData Object to HubSpot schema
    function convertFormDataToHubSpotSchema(formData) {
      const fields = [...formData.entries()].map(entry => ({
        objectTypeId: "0-1",
        name: entry[0],
        value: entry[1]
      }));
      return {
        fields
      };
    }
  }
}