export default class PartsJourneySection {
    constructor(c) {
        this.$el = c;
        this.$journeyBody = this.$el.querySelector(".journey__body");
        this.$stepsContainer = this.$el.querySelector(".journey__steps");
        this.$stepsItems = this.$el.querySelectorAll(".journey__step");
        this.$sections = this.$el.querySelectorAll(".journey__content-item");
        this.$sectionsArray = gsap.utils.toArray(".journey__content-item");
        this.currentIndex = 0;
        this.mm = gsap.matchMedia();
        this.init();
    }

    init() {
        this.mm.add("(min-width: 1200px)", () => {

            let sectionWidth = this.$sections[0].offsetWidth;
            let totalWidth = 0;
            let gap = 32;
            this.$sectionsArray.forEach((section) => {
                totalWidth += section.offsetWidth;
                totalWidth += gap; // Add gap width
            });
            totalWidth -= gap; // Remove last gap width
            totalWidth -= sectionWidth; // Remove last gap width

            // Horizontal scroll setup for desktop
            const tl = gsap.timeline({
                scrollTrigger: {
                    // markers: true,
                    trigger: this.$journeyBody,
                    pin: true,
                    pinSpacing: true,
                    scrub: 1,
                    snap: {
                        snapTo:  1 / (this.$sectionsArray.length - 1),
                        duration: { min: 0.3, max: 0.5 },
                        delay: 0.1,
                    },
                    end: () => {
                        const sectionWidth = this.$sections[0].offsetWidth;
                        const spacing = 32;
                        const totalWidth = sectionWidth + spacing;
                        return (
                            "+=" +
                            (totalWidth * this.$sectionsArray.length - spacing)
                        );
                    },
                    onUpdate: (self) => {
                        const progress = Math.min(
                            Math.floor(
                                self.progress * this.$sectionsArray.length
                            ),
                            this.$sectionsArray.length - 1
                        );
                        if (progress !== this.currentIndex) {
                            this.setActiveStep(progress);
                        }
                    },
                },
            });

            tl.to(this.$sectionsArray, {
                xPercent: -100 * (this.$sectionsArray.length - 1),
                ease: "none",
            });

            return () => {
                // Cleanup function
                tl.kill();
                this.resetLayout();
            };
        });

        this.mm.add("(max-width: 1199px)", () => {
            // Mobile setup
            this.resetLayout();
            this.setupMobileScrollTriggers();
        });
    }

    // Vertical scroll for mobile
    setupMobileScrollTriggers() {
        this.$sections.forEach((section, index) => {
            ScrollTrigger.create({
                // markers: true,
                invalidateOnRefresh: true,
                trigger: section,
                start: "top center",
                end: "bottom center",
                onEnter: () => this.setActiveStep(index),
                onEnterBack: () => this.setActiveStep(index),
            });
        });
    }

    resetLayout() {
        // Reset any styles or classes applied for the horizontal scroll
        gsap.set(this.$sectionsArray, { clearProps: "all" });
        // Kill any existing ScrollTriggers
        ScrollTrigger.getAll().forEach((st) => st.kill());
    }

    setActiveStep(index) {
        // console.log("set active step", index);
        this.currentIndex = index;
        this.$stepsItems.forEach((step, i) => {
            if (i === index) {
                step.classList.add("active");
            } else {
                step.classList.remove("active");
            }
        });
    }
}
