export default class Newsletter {

	constructor(c) {

		const emailInput = c.querySelector('.newsletter__input input[type=email]');
		const trigger = c.querySelector('.newsletter__input button[type=submit]');
		const modal = document.getElementById('newsletter-modal');
		
		trigger.addEventListener('click', (e) => {
			e.preventDefault();
			const hsForm = modal.querySelector('.hs-form');
			const emailTarget = hsForm.querySelector('input[name=email]');
			
			// get the email value from the input
			const emailValue = emailInput.value;

			// set the email value of emailTarget
			emailTarget.value = emailValue;
		});

	}
}