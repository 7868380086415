export default class ManufacturersFilter {

	constructor(c) {

		this.container = document.getElementById('manufacturersMenu');
		this.filter = new Set();
		this.filterButtons = c.querySelectorAll('.filter-button');
		this.products = this.container.querySelectorAll('.product-link');
		this.manufacturers = this.container.querySelectorAll('.manufacturer');
		this.noResults = this.container.querySelector('.no-results');
		this.resetButton = this.container.querySelector('.reset-filter');

		// Click on a filter button
		this.filterButtons.forEach((filterButton) => {
			filterButton.addEventListener('click', () => {
				this.updateFilter(filterButton);
			});
		});

		// Click on reset-filter button
		this.resetButton.addEventListener('click', () => {
			this.resetFilter();
		});
	}


	// Update the filter according to the active filter buttons
	updateFilter(filterButton) {

		// Check if filter button is a toggle filter (radio style)
		let type = filterButton.getAttribute('data-filterType');
		if (type === "toggle" && !filterButton.classList.contains("active")) {
			let filterGroup = filterButton.getAttribute('data-filterGroup');
			let allButtons = this.container.querySelectorAll(`.filter-button[data-filterGroup=${filterGroup}`);

			// Deactivate other toggle buttons first
			allButtons.forEach(b => {
				// Deactivate
				b.classList.remove("active");
				// Remove from filter Set
				let filterTag = b.getAttribute('data-filterSource');
				this.filter.delete(filterTag);
			});
		}

		// Toggle filter button
		filterButton.classList.toggle("active");

		// Get filter tag and add/remove it from the filter set
		let filterTag = filterButton.getAttribute('data-filterSource');
		filterButton.classList.contains("active") ? this.filter.add(filterTag) : this.filter.delete(filterTag);

		// Update the results
		this.updateResults();

	}


	// Hide show products and manufacturers based on the filter array
	updateResults() {

		// PRODUCTS
		// For each product
		this.products.forEach(product => {

			// All products should be visible initialy
			product.classList.remove("hide");
			let filterTarget = product.getAttribute("data-filterTarget");

			// Check each filter set entry
			for (const element of this.filter) {

				// Hide product if the filter tag does not apply to the filter target
				if (!filterTarget.includes(element))
					product.classList.add("hide");
			}
		});

		// MANUFACTURERS
		// For each manufacturer
		this.manufacturers.forEach(manufacturer => {

			// All manufacturers should be visible initialy
			manufacturer.classList.remove("hide");

			// Get all visible products of the manufacturer 
			let visibleProducts = manufacturer.querySelectorAll('.product-link:not(.hide)');

			// Hide manufacturer if there are no visible products
			if (visibleProducts.length < 1)
				manufacturer.classList.add("hide");
		});

		// Check for empty product-groups
		this.checkEmptyGroups();
		

		// NO RESULTS
		// Get all visible products of the manufacturer 
		let visibleManufacturers = this.container.querySelectorAll('.manufacturer:not(.hide)');

		// Hide manufacturer if there are no visible products
		visibleManufacturers.length < 1 ? this.noResults.classList.add("show") : this.noResults.classList.remove("show");
	}


	// Reset all filter buttons
	resetFilter() {

		// Reset filter
		this.filter = new Set();

		// Reset buttons
		this.filterButtons.forEach((filterButton) => {
			filterButton.classList.remove("active");
		});

		this.updateResults();
	}


	// Check for empty product-groups
	checkEmptyGroups() {

		// For each product-group
		let productGroups = this.container.querySelectorAll('.product-group');
		productGroups.forEach(productGroup => {

			// Get all visible products of the product-group 
			let visibleProducts = productGroup.querySelectorAll('.product-link:not(.hide)');

			// Hide product-group if there are no visible products
			visibleProducts.length < 1 ? productGroup.classList.add("hide") : productGroup.classList.remove("hide");
		});
	}

}