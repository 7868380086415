export default class HomeIntro {

  constructor(c) {

    // this.ytAllowed = document.body.getAttribute('data-youtube') ? true : false;
    this.features = this.getFeatureObjects(c);
    this.activeFeature = null;

    // Start looping through features
    if (this.features) this.loopFeatures(0);

    // console.log(this.features);

  }

  // Loop through features
  loopFeatures(id) {

    if (!this.activeFeature) {
      // Start with first feature
      // console.log("Start with first feature");
      this.startFeature(id);
    } else {
      // Keep going with the next one in the array
      // console.log("Keep going with the next one in the array");
      if (id < this.features.length) {
        // If a previous feature is still active ...
        if (this.activeFeature.progressTL.isActive() || this.activeFeature.ytPlayer) {
          // console.log("If a previous feature is still active ...");
          // ... deactivate it
          // console.log("... deactivate it", this.activeFeature.id);
          this.activeFeature.deactivate();
          // ... and start the new one
          // console.log("... and start the new one", id);
          this.startFeature(id);
        } else {
          // Or just start the new one
          // console.log("Or just start the new one", id);
          this.startFeature(id);
          // this.loopFeatures(id + 1);
        }
      } else {
        // Or start over with the first one
        this.loopFeatures(0);
      }
    }

  }

  // Get the feature, start it and set it as the current one
  startFeature(id) {
    let f = this.features[id];
    f.activate();
    this.activeFeature = f;
  }

  // Create feature object with animation timeline and event listeners
  getFeatureObjects(c) {

    let that = this;
    let features = [];

    // Build objects
    c.querySelectorAll('.feature').forEach(f => {

      // Feature object
      let feature = {

        domObj: f,
        id: parseInt(f.dataset.id),
        video: c.querySelector(`.video[data-id="${f.dataset.id}"] video`),
        ytPlayer: null,
        ytPlaying: false,
        button: c.querySelector(`.video[data-id="${f.dataset.id}"] button`),
        progressTL: null,

        activate: function () {
          this.domObj.classList.add('active');
          this.video?.parentElement.classList.add('active');
          this.progressTL.play();
          if (this.ytPlayer) {
            this.ytPlayer.playVideo();
          }
        },

        deactivate: function () {
          this.domObj.classList.remove('active');
          this.video?.parentElement.classList.remove('active');
          this.progressTL.pause(0);
          if (this.ytPlayer) {
            this.ytPlayer.pauseVideo();
            console.log("pause video");
          }
        },

        pause: function () {
          if (this.progressTL.isActive()) {
            this.progressTL.pause();
          }
        },

        resume: function () {
          if (this.progressTL.paused() && this.progressTL.progress() > 0) {
            this.progressTL.resume();
          }
        },

      }

      // Add timeline to feature object
      let tl = gsap.timeline({
        onComplete: () => {
          feature.deactivate();
          that.loopFeatures(parseInt(feature.id) + 1); // Start next feature
        }
      });
      let train = f.querySelector('.train');
      tl.to(train, {
        duration: 6,
        width: "100%",
        ease: "linear",
        clearProps: "width",
      })
      tl.pause(0);
      feature.progressTL = tl;

      // Activate feature on click
      feature.domObj.addEventListener('click', () => {
        // feature.activate();
        console.log("-- feature clicked");
        that.loopFeatures(feature.id);
      });

      // Pause feature on hover
      feature.domObj.addEventListener('mouseenter', () => {
        feature.pause();
      });

      // Pause feature on hover
      feature.domObj.addEventListener('mouseleave', () => {
        if (!feature.ytPlaying) {
          feature.resume();
        }
      });

      // Add YouTube video to feature
      if (feature.button) {
        feature.button.addEventListener('click', function () {
          that.activeFeature.pause();
          gsap.to(feature.button, {
            duration: 0.2,
            x: "+=100%",
            ease: "ease-out"
          })
          if (feature.ytPlayer === null) {
            let ytSrc = this.getAttribute('data-yt');
            feature.ytPlayer = that.initYT(ytSrc, feature.id);
          } else {
            ytPlayer.i.classList.remove('hidden');
          }
        })
      }

      // Add feature to features list
      features.push(feature);

    });

    return features;

  }


  initYT(ytID, id) {

    let that = this;

    // console.log("init new yt video", ytID, id);

    if(checkIfScriptExists("https://www.youtube.com/iframe_api")) {
      createYTPlayer();
    }

    // 2. This code loads the IFrame Player API code asynchronously.
    let tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // 3. This function creates an <iframe> (and YouTube player)
    //    after the API code downloads.

    window.onYouTubeIframeAPIReady = function () {
      createYTPlayer();
    }

    function createYTPlayer() {
      player = new YT.Player(`youtube-${id}`, {
        playerVars: {
          controls: 1,
          rel: 1,
          showInfo: 0,
          modestbranding: 0,
        },
        height: '360',
        width: '640',
        videoId: ytID,
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange
        }
      });
    }

    // 4. The API will call this function when the video player is ready.
    function onPlayerReady(event) {
      // ytPlayer = player;
      let iframe = document.querySelector('iframe#player');
      gsap.from(iframe, {
        duration: 0.6,
        autoAlpha: 0,
        onStart: () => {
          player.playVideo();
          // console.log(player);
          that.features[id].ytPlayer = player;
        }
      })
    }

    // 5. The API calls this function when the player's state changes.
    //    The function indicates that when playing a video (state=1),
    //    the player should play for six seconds and then stop.

    function onPlayerStateChange(event) {
      if (event.data == YT.PlayerState.PLAYING) {
        // console.log("YT VIDEO STARTED: ", player.m);
        that.activeFeature.ytPlaying = true;
      }
      if (event.data == YT.PlayerState.PAUSED) {
        // console.log("YT VIDEO PAUSED");
        that.activeFeature.ytPlaying = false;
      }
    }


    function checkIfScriptExists(url) {
      if (!url) url = "https://www.youtube.com/iframe_api";
      var scripts = document.getElementsByTagName('script');
      for (var i = scripts.length; i--;) {
        if (scripts[i].src == url) return true;
      }
      return false;
    }
  }


}