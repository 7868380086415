export default class TeamSlider {

  constructor(el) {

    this.$el = el;
    this.swiper = this.initSwiper();

  }


  initSwiper() {

    const swiper = new Swiper(this.$el.querySelector('.swiper'), {
      slidesPerView: 1.5,
      speed: 660,
      spaceBetween: 0,
      centeredSlides: true,
      grabCursor: true,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: this.$el.querySelector(".swiper-pagination"),
        clickable: true,
        dynamicBullets: true,
      },
      navigation: {
        nextEl: this.$el.querySelector(".swiper-button-next"),
        prevEl: this.$el.querySelector(".swiper-button-prev"),
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    });

    return swiper;

  }

}