export default class PartstogoLandingpageProductStacks {
    constructor(c) {
        this.$container = c;
        this.count = parseInt(c.getAttribute("data-count"), 10); // Parse count as an integer
        this.$imgSrc = c
            .querySelector(".landingpage-dl-products__item-image img")
            .getAttribute("src"); // Get image source

        this.stackImages(); // Call the method to stack images
        this.animateStack(); // Call the animation method

        window.addEventListener("load", () => {
            ScrollTrigger.refresh();
        });
    }

    stackImages() {
        // Clear any existing images inside the container (if necessary)
        this.$container.innerHTML = "";

        for (let i = 0; i < this.count; i++) {
            const img = document.createElement("img"); // Create a new image element
            img.src = this.$imgSrc; // Set the image source from the original image
            img.classList.add("cloned"); // Add the same class as the original image
            img.alt = `Stacked image ${i + 1}`; // Optional: Add alt text for accessibility
            img.loading = "lazy"; // Apply lazy loading for performance
            img.style.setProperty("--i", i); // Use CSS variable for index-based transformations
            // Append the new image to the container
            this.$container.appendChild(img);
        }
    }

    animateStack() {
        // Create GSAP timeline for animation
        const images = this.$container.querySelectorAll(".cloned");

        // Exclude the first image from the animation by selecting only images after the first one
        const imagesToAnimate = Array.from(images).slice(1); // Select all images except the first

        gsap.from(imagesToAnimate, {
            opacity: 0,
            x: "-=10",
            duration: 0.5,
            stagger: 0.1,
            scrollTrigger: {
                trigger: this.$container, // Trigger animation when container enters view
                start: "top 50%", // Adjust when the animation starts (80% of viewport)
                toggleActions: "play none none none", // Play forward, reset on reverse
                // markers: true
            },
        });
    }
}