export default class TemplateSidebar {

	constructor(c) {

		if (window.innerWidth > 960) {

			const headerHeight = document.getElementById('header').offsetHeight;
			const trigger = document.getElementById('sidebar');

			// Pin Sidebar
			ScrollTrigger.create({
				// markers: true,
				trigger: sidebar,
				endTrigger: '#footer',
				start: `top +=${headerHeight + 32}`,
				end: `top +=${trigger.offsetHeight + trigger.offsetTop}`,
				pin: true,
				pinSpacing: false,
				anticipatePin: 1,
			});

		}
	}
}