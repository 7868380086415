import {
	Fancybox
} from "@fancyapps/ui";

class ImageZoom {

	constructor() {


		Fancybox.bind('.fancybox', {
			// Your options go here
			infinite: false,
			on: {
				ready: (fancybox) => {
					console.log(`fancybox #${fancybox.id} is ready!`);
				}
			}
		});

	}

}

export default ImageZoom;