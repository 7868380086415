export default class StratasysAccordions {

	constructor(c) {
		
		this.$accordions = c.querySelectorAll('.stratasys-accordions__item');
		this.$accordions.forEach(a => {
			const toggleButton = a.querySelector('.stratasys-accordions__header');
			toggleButton.addEventListener('click', () => {
				if (a.classList.contains('active')) {
					this.closeAccordion(a);
				} else {
					this.openAccordion(a);
				}
			})
		})

	}



	openAccordion(a) {

		// Close all other accordions first
		let openAccordions = a.closest('.stratasys-accordions__item').querySelectorAll('.stratasys-accordions__item.active');
		if (openAccordions.length) {
			openAccordions.forEach((oa) => {
				this.closeAccordion(oa);
			});
		}

		// Open new accordion
		a.classList.add('active');

	}



	closeAccordion(a) {
		a.classList.remove('active');
	}

}