export default class Popup {

	constructor(c) {

		this.toggleButton = document.getElementById("popupSwitch");
		this.popup = document.getElementById("popup");
		this.delay = 2000;
		this.popupShown = localStorage.getItem('popupShown');
		this.cookiesSeen = localStorage.getItem('cookiesSeen');
		this.version = this.popup.dataset.version;
		this.closeOnOutsideClick();
		this.togglePopup();

		// Check if popupShown localStorage Item exists and is false
		// If it does not exist, show popup
		if (this.popupShown === null && this.cookiesSeen) {
			console.log('User has not seen any popup yet');
			this.showInitialPopup();
		} 
		else if (this.popupShown && !this.hasSeenCurrentPopupVersion(this.version)) {
			console.log('user has not seen the new popup');
			this.showInitialPopup();
		}

	}



	togglePopup() {
		this.toggleButton.addEventListener("click", () => {
			if (this.popup.open) {
				setTimeout(() => {
					this.popup.close();

				}, 100);
			} else {
				setTimeout(() => {
					this.popup.show();
				}, 100);
			}
		});
	}


	closeOnOutsideClick() {
		document.addEventListener("click", (e) => {
			if (this.popup.open && !e.target.closest('#popup')) {
				this.popup.close();
			}
		});
	}


	showInitialPopup() {
		setTimeout(() => {
			this.popup.show();
			localStorage.setItem('popupVersion', this.version.toString());
			localStorage.setItem('popupShown', 'true');
		}, this.delay);
	}


	hasSeenCurrentPopupVersion(currentVersion) {
		const storedVersion = localStorage.getItem('popupVersion');
		return storedVersion === currentVersion.toString();
	}

}