export default class productFilter {

  constructor(c) {

    this.container = c;
    this.slider = null;
    this.filterDetails = {};
    this.filter = {};
    this.featured = c.querySelector('.filter-list').getAttribute('data-featured').split(",").filter(x => x !== "");
    this.categoryButtons = c.querySelectorAll('.filter-category-button');
    this.filterButtons = c.querySelectorAll('.filter-button');
    this.noResults = c.querySelector('.no-results');
    this.fetchController;
    this.containerHeight = 0;

    // Open/close categories
    this.categoryButtons.forEach((b) => {
      b.addEventListener('click', (e) => {
        let toggle = e.currentTarget;
        if (toggle.classList.contains('active')) {
          toggle.classList.remove('active');
        } else {
          let listItems = [...toggle.closest('ul').children];
          listItems.forEach((toggle) => {
            toggle.classList.remove('active');
          });
          toggle.classList.add('active');
        }
      })
    });

    // Listen for filter buttons
    this.filterButtons.forEach((b) => {
      b.addEventListener('click', (e) => {

        // Abort all ongoing fetches
        if (this.fetchController) {
          this.fetchController.abort();
        }

        let el = e.currentTarget;
        let cb = el.closest('.filter-dropdown').previousElementSibling;
        let cbText = cb.querySelector('.text');
        // Deactivate all other filter-buttons
        el.closest('ul').querySelector('.filter-button.active').classList.remove('active');
        // Activate this filter-button
        el.classList.add('active');
        // Close dropdown
        cb.classList.remove('active');
        // Set Category button text
        cbText.innerText = el.innerText;
        // Update the filter
        this.updateFilter();
      })
    });

    // Close filter on outside click
    window.addEventListener('click', function (e) {
      let filterDropdown = c.querySelector('.filter-category-button.active');
      if (filterDropdown) {
        if (!filterDropdown.contains(e.target) && !(e.target.parentElement.classList.contains('filter-category-button') || e.target.classList.contains('filter-category-button'))) {
          filterDropdown.classList.remove('active');
        }
      }
    });

    // Create slider initialy
    this.createSlider();

    // Update filter initialy
    this.updateFilter();

  }



  // Calculate which products to show
  // Results in an updated "filter" array
  updateFilter() {

    // Create filterDetails object
    this.categoryButtons.forEach(cb => {
      let category = cb.getAttribute('data-category');
      let activeFilterButton = cb.parentElement.querySelector('.filter-button.active');
      if (activeFilterButton) {
        this.filterDetails[category] = activeFilterButton.getAttribute('data-filter').split(",").filter(x => x !== "");
      }
    })

    // Merge all filter arrays in one array
    let merged = [];
    for (let products in this.filterDetails) {
      merged = merged.concat(this.filterDetails[products]);
    }

    // Save all products that appear more than once (intersecting) into the filter array
    let filter = [];
    merged.forEach(v => {
      if (merged.filter(x => x === v).length > 1 && !filter.includes(v)) {
        filter.push(v);
      }
    });

    // Sort products starting with featured (Array) products
    let featuredArray = this.featured;
    filter.sort((a, b) => featuredArray.indexOf(a) - featuredArray.indexOf(b)).reverse();

    // Final filter array
    this.filter = filter;

    // Show "no results" text if needed be
    if (!this.filter.length) {
      this.noResults.classList.add('active');
    } else {
      this.noResults.classList.remove('active');
    }

    // Now update results (slides)
    this.updateResults();

  }



  // Handle the slider slides based on the filter array
  updateResults() {

    // Delete all slides first
    if (this.slider) {
      this.slider.removeAllSlides();
    }

    // Then load all slides again
    this.loadProducts();

  }



  // Fetch products from the backend
  // Load featured products in order
  loadProducts() {

    // Prepare to abort ongoing fetches
    this.fetchController = new AbortController();
    const s = this.fetchController.signal;

    // Go through the filter object and load every product in it
    this.filter.forEach(p => {

      // Request from json template
      fetch(p, {
          signal: s
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error: ${response.status}`);
          }
          // Pass the results
          return response.text();
        })
        .then((product) => {

          // Add the slide append or prepend (featured) slide
          this.featured.includes(p) ? this.addSlide(product, true) : this.addSlide(product, false); 

        })
        .catch((error) => console.log(`Could not fetch product: ${error}`));

    })

  }



  // Create the slider (swiper)
  createSlider() {
    this.slider = new Swiper(this.container.querySelector('#results-slider'), {
      centeredSlides: true,
      slidesPerView: 1,
      spaceBetween: 0,
      breakpoints: {
        600: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        1000: {
          slidesPerView: 3,
          centeredSlides: false,
        },
        1600: {
          slidesPerView: 4,
          spaceBetween: 0,
          centeredSlides: false,
        },
      },
      pagination: {
        el: this.container.querySelector('.swiper-pagination'),
        type: 'fraction',
      },
      navigation: {
        nextEl: this.container.querySelector('.swiper-button-next'),
        prevEl: this.container.querySelector('.swiper-button-prev'),
      },
      a11y: false
    });
  }



  // Add a slide to the slider 
  addSlide(s, prepend) {

    let slideHTML = '<li class="swiper-slide">' + s + '</li>';

    // Add slide to the DOM
    if (prepend) {
      // console.log("prepend: ", s);
      this.slider.prependSlide(slideHTML);
    } else {
      this.slider.appendSlide(slideHTML);
    }

    // Add active class in order to animate
    let slide = this.container.querySelector('.product-card:not(.active)');
    slide.classList.add('active');

    // Add min height to the results to avoid jumping layout
    if (this.containerHeight === 0) {
      const results = this.container.querySelector('.results');
      this.containerHeight = slide.offsetHeight;
      results.style.minHeight = slide.offsetHeight + "px";
    }
  }

}