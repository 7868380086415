import drawSVG from '../vendor/DrawSVGPlugin';

export default class MobileNav {

	constructor() {

		// Avoid tree shaking error in production build
		const plugins = [drawSVG];
		gsap.registerPlugin(drawSVG);

		// Variables 
		const body = document.body;
		const navBtn = document.getElementById('mobileSwitch');
		const nav = document.getElementById('nav');
		const sidebar = document.getElementById('header-right');

		// Styles
		const colorWhite = getComputedStyle(document.documentElement).getPropertyValue('--c-white');
		const colorHighlight = getComputedStyle(document.documentElement).getPropertyValue('--c-highlight');

		// build button animation
		const mobileTL = gsap.timeline({
			onComplete: () => {
				body.classList.add('mobile-nav-open');
			},
			onReverseComplete: () => {
				body.classList.remove('mobile-nav-open');
				let closeNav = new CustomEvent('PTG--closeNavigation');
				document.dispatchEvent(closeNav);
			},
		});

		// Mobile Navigation Switch Timeline
		const
			navBtnSvg = navBtn.querySelector('#mobileSwitchSVG'),
			burger = navBtnSvg.querySelectorAll('#burger line'),
			close = navBtnSvg.querySelector('#close'),
			closeUp = navBtnSvg.querySelector('#close line#up'),
			closeDown = navBtnSvg.querySelector('#close line#down'),
			content = document.querySelector('main');

		mobileTL.set(close, {
			opacity: 1
		});
		mobileTL.staggerFromTo(burger, 0.4, {
			x: "0",
			drawSVG: "0% 100%"
		}, {
			stroke: colorWhite,
			x: "+=10",
			drawSVG: "0% 0%"
		}, 0.1);
		mobileTL.fromTo(closeDown, 0.4, {
			x: "-=10",
			y: "-=10",
			drawSVG: "100% 100%"
		}, {
			stroke: colorWhite,
			x: "0",
			y: "0",
			drawSVG: "0% 100%"
		}, "-=0.6");
		mobileTL.fromTo(closeUp, 0.4, {
			x: "-=10",
			y: "+=10",
			drawSVG: "100% 100%"
		}, {
			stroke: colorWhite,
			x: "0",
			y: "0",
			drawSVG: "0% 100%"
		}, "-=0.6");
		mobileTL.to(navBtn, 0.4, {
			background: colorHighlight
		}, "-=0.6");
		mobileTL.to(nav, 0.4, {
			x: "0"
		}, "-=0.6");
		mobileTL.to(sidebar, 0.4, {
			x: "0"
		}, "-=0.6");
		mobileTL.reverse();

		navBtn.addEventListener('click', toggleSwitcher);

		function toggleSwitcher() {
			if (!mobileTL.isActive()) {
				// Only reverse the direction if the tween is not active
				mobileTL.reversed() ? mobileTL.play() : mobileTL.reverse();
			}
		}

	}
}