export default class ShopSlider {

	constructor(c) {
		c.forEach(s => {
			this.init(s);
		});
	}

	init(s) {

		let thumbs = new Swiper(s.querySelector(".shop-slider__thumbs"), {
			spaceBetween: 8,
			slidesPerView: 5,
			freeMode: true,
			watchSlidesProgress: true
		});

		new Swiper(s.querySelector(".shop-slider__main"), {
			spaceBetween: 0,
			thumbs: {
				swiper: thumbs,
			}
		});

	}
}