class Slider {

	constructor(c) {
		c.forEach(s => {
			this.init(s);
		});
	}

	init(s) {
		new Swiper(s, {
			slidesPerView: 2,
			spaceBetween: 8,
			breakpoints: {
				320: {
					slidesPerView: 1,
				},
				768: {
					slidesPerView: 2,
				},
				1920: {
					slidesPerView: 3,
				},
			},
			pagination: {
				el: s.querySelector(".swiper-pagination"),
				type: "fraction",
			},
			navigation: {
				nextEl: s.querySelector(".swiper-button-next"),
				prevEl: s.querySelector(".swiper-button-prev"),
			},
			// on: {
			// 	init: function (e) {
			// 		console.log("slider initialized: ", e);
			// 	}
			// }
		});
	}
	
}

export default Slider;