import drawSVG from '../vendor/DrawSVGPlugin';

class Hexagon {

	constructor() {

		// Avoid tree shaking error in production build
		const plugins = [drawSVG];
		gsap.registerPlugin(drawSVG);

		// Variables 
		const hexagons = document.querySelectorAll('svg.hexagon');
		const paths = document.querySelectorAll('svg.hexagon path');

		// Make hexagons visible
		gsap.set(hexagons, {
			opacity: 1
		})

		// Animate hexagon
		gsap.from(paths, 0.9, {
			drawSVG: 0,
			stagger: 0.3,
			ease: Power2.easeOuteaseOut
		})

	}

}

export default Hexagon;