export default class Scrollspy {

	constructor() {
		const header= document.getElementById('header');
		if(!header) return;
		
		const headerHeight = document.getElementById('header').offsetHeight;
		const scrollspyHeight = document.getElementsByClassName('scrollspy')[0].offsetHeight;
		const headlineTop = document.querySelector('.headline.pinminify')?.offsetTop;
		const headlineHeight = document.querySelector('.headline.pinminify')?.offsetHeight;
		const bannerHeight = document.getElementById('promotion-banner') ? document.getElementById('promotion-banner').offsetHeight : 0;
		const template = document.querySelector('main');
		if (!template.classList.contains('template-sidebar')) {
			this.pinMinifySidebar(headlineTop, headlineHeight, headerHeight, bannerHeight);
		}
		this.highlightScrollspy();
		this.scrollToSection(headerHeight, scrollspyHeight);
	}

	pinMinifySidebar(ht, headlineHeight, hh, bh) {
		// console.log("make it sticky");
		ScrollTrigger.create({
			trigger: '#sidebar',
			endTrigger: '#footer',
			// markers: true,
			pin: true,
			pinSpacing: false,
			start: `top ${hh + bh}`,
			end: "top 100%",
			// onEnter: function () { 
			// 	console.log("pin scrollbar");
			// },
			// onLeave: function () {
			// 	console.log("unpin scrollbar");
			// },
		});
	}

	highlightScrollspy() {
		const sections = document.querySelectorAll("#content >.scrollspy-target");
		const sectionsArray = gsap.utils.toArray(sections);
		const scrollspy = document.getElementsByClassName("scrollspy")[0];

		if (sections.length) {
			sectionsArray.forEach((s) => {
				ScrollTrigger.create({
					trigger: s,
					// markers: true,
					start: `top 50%`,
					end: "bottom 50%",
					onEnter: function () {
						let button = document.querySelector(`.scrollspy a[href="#${s.id}"]`);
						if (button) button.classList.add('active');
						if (window.innerWidth < 960) scrollToPosition(button);
					},
					onLeave: function () {
						let button = document.querySelector(`.scrollspy a[href="#${s.id}"]`);
						if (button) button.classList.remove('active');
					},
					onEnterBack: function () {
						let button = document.querySelector(`.scrollspy a[href="#${s.id}"]`);
						if (button) button.classList.add('active');
						if (window.innerWidth < 960) scrollToPosition(button);
					},
					onLeaveBack: function () {
						let button = document.querySelector(`.scrollspy a[href="#${s.id}"]`);
						if (button) button.classList.remove('active');
					},
				});
			});

			// Horizontal scrolling for mobile view
			function scrollToPosition(button) {
				if (button) {
					let xPadding = Math.round(scrollspy.getBoundingClientRect().x);
					let pos = Math.round(button.getBoundingClientRect().x) - xPadding;
					gsap.to('#sidebar', {
						duration: 0.6,
						scrollTo: {
							x: pos
						}
					});
				} else {
					console.warn("SCROLLSPY ERROR HERE");
				}

			}
		}
	}

	// Scroll to section on scrollspy click
	scrollToSection(hh, sh) {
		const anchors = document.querySelectorAll('.scrollspy > li > a');
		let offset = 0;
		if (window.innerWidth < 960) {
			offset = hh + sh;
		} else {
			offset = hh;
		}
		anchors.forEach(a => {
			a.addEventListener('click', (e) => {
				e.preventDefault();
				let target = document.querySelector(a.getAttribute('href'));
				// console.log("scrollto: ", a.getAttribute('href'), offset, target, target.offsetTop);
				gsap.to(window, {
					duration: 1.2,
					scrollTo: {
						y: target.offsetTop - offset,
					}
				});
			})
		})
	}
}