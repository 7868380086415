export default class PartsToGoBoxSlider {
    constructor(c) {
        this.el = c;
        this.init();
    }

    init() {
        console.log("PartsToGoBoxSlider initialized: ", this.el);
        new Swiper(this.el, {
            slidesPerView: 2,
            spaceBetween: 16,
            breakpoints: {
                640: {
                    slidesPerView: 2.5,
                },
                768: {
                    slidesPerView: 4,
                },
                1200: {
                    slidesPerView: 5.5,
                },
            },
            grabCursor: true,
            navigation: {
                nextEl: this.el.querySelector(
                    ".partstogo-trust__box-slider-button--next"
                ),
                prevEl: this.el.querySelector(
                    ".partstogo-trust__box-slider-button--prev"
                ),
            },
            speed: 600,
            loop: false,
            // spaceBetween: 64,
            on: {
                init: function (e) {
                    console.log("PartsToGoBoxSlider initialized: ", e);
                },
            },
        });
    }
}
