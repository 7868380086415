export default class PartsToGoSlider {
    constructor(c) {
        this.el = c;
        this.init();
    }

    init() {
        new Swiper(this.el, {
            autoplay: {
                delay: 6000,
                disableOnInteraction: true,
            },
            pagination: {
                el: this.el.querySelector(".partstogo-slider__pagination"),
                clickable: true,
            },
            navigation: {
                nextEl: this.el.querySelector(".partstogo-slider__button--next"),
                prevEl: this.el.querySelector(".partstogo-slider__button--prev"),
            },
            speed: 600,
            loop: false,
            // spaceBetween: 64,
            // on: {
            // 	init: function (e) {
            // 		console.log("PartsToGoSlider initialized: ", e);
            // 	}
            // }
        });
    }
}
