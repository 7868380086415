export default class SpecialTeaser {

	constructor(c) {

		this.$image = c.querySelector('.special__image img');

		console.log("SpecialTeaser", this.$image);
		
		if (this.$image) {
			new Ukiyo(this.$image, {
				scale: 1.1,
				speed: 1.5, // 1~2 is recommended
				willChange: true, // This may not be valid in all cases
			})
		}
	}
}