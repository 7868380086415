export default class ProductionJourneySection {
    constructor(c) {
        this.$el = c;
        this.$stepsContainer = this.$el.querySelector(".journey__steps");
        this.$steps = this.$el.querySelectorAll(".journey__step");
        this.$focus = this.$el.querySelector(".journey__steps-focus");
        this.$circleSVG = this.$el.querySelector(".journey__steps-svg");
        this.$sections = this.$el.querySelectorAll(".journey__content-item");
        this.$media = this.$el.querySelectorAll(".journey__content-media");
        this.$stickyImageContainer = this.$el.querySelector(
            ".journey__sticky-image-inner"
        );
        this.$stickyImages = null;
        this.currentIndex = 1;
        this.numSegments = this.$steps.length;
        this.paths = this.$circleSVG.querySelectorAll("path");
        this.segmentsTL = this.createTimeline();
        this.init();
    }

    init() {
        // Show steps
        gsap.to(this.$el.querySelector('.journey__steps-container'), {
            opacity: 1,
            duration: 0.4,
            ease: CustomEase.create("custom", "0.33, 1, 0.68, 1"),
        });

        // Prepare sticky images by copying them into the .journey__sticky-image-container
        this.$media.forEach((img, i) => {
            const clone = img.cloneNode(true);
            this.$stickyImageContainer.appendChild(clone);
        });

        this.$stickyImages = this.$stickyImageContainer.querySelectorAll(
            ".journey__content-media"
        );

        // Find all videos and start them
        this.$el.querySelectorAll("video").forEach((video) => {
            video.play();
        });

        // Now show the first image initially
        this.updateImage(0);
        this.centerActiveStep(0);

        this.$sections.forEach((section, index) => {
            ScrollTrigger.create({
                // markers: true,
                invalidateOnRefresh: true,
                trigger: section,
                start: "top center",
                end: "bottom center",
                onEnter: () => {
                    this.setActiveStep(index);
                    this.updateImage(index, true);
                },
                onEnterBack: () => {
                    this.setActiveStep(index);
                    this.updateImage(index, false);
                },
            });
        });

        // Refresh Scrolltrigger on window size change
        let resizeTimeout;
        window.addEventListener("resize", () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                ScrollTrigger.getAll().forEach((trigger) => {
                    trigger.kill();
                });
                this.init();
            }, 500);
        });
    }

    setActiveStep(index) {
        // console.log("active step: ", index+1);
        this.currentIndex = index;
        this.$steps.forEach((step, i) => {
            if (i === index) {
                step.classList.add("active");
            } else {
                step.classList.remove("active");
            }
        });
        this.centerActiveStep(index);
        this.segmentsTL.tweenTo(`step${index}`);
    }

    centerActiveStep(index) {
        const focusOffset = this.$focus.offsetHeight / 2;
        const stepsHeight = this.$stepsContainer.offsetHeight;
        const stepHeight = this.$steps[index].offsetHeight;
        const stepOffset = this.$steps[index].offsetTop;

        const translateY =
            stepsHeight -
            (stepsHeight / 2 + stepOffset - focusOffset) -
            stepHeight;

        this.$stepsContainer.style.transform = `translateY(${translateY}px)`;
    }

    createTimeline() {
        let tl = gsap.timeline({ paused: true });
        this.paths.forEach((segment, index) => {
            const label = `step${index}`;

            tl.fromTo(
                segment,
                {
                    drawSVG: "100% 100%",
                },
                {
                    drawSVG: "0% 100%",
                    delay: 0.4,
                    duration: 0.4,
                    ease: CustomEase.create("custom", "0.33, 1, 0.68, 1"),
                }
            ).add(label);
        });

        return tl;
    }

    updateImage(index, up) {
        const containerHeight = this.$stickyImageContainer.offsetHeight;
        gsap.to(this.$stickyImages, {
            y: () => {
                return this.$stickyImageContainer.offsetHeight * index * -1;
            },
            duration: 0.4,
            ease: CustomEase.create("custom", "0.33, 1, 0.68, 1"),
        });
    }
}
