export default class UsecasesFilter {

	constructor(c) {

		this.$filter = c;
		this.$filterCheckboxes = c.querySelectorAll('.filter-checkbox');
		this.$cards = document.querySelectorAll('.usecases__item');
		this.filterSet = new Set();

		// On filter checkbox change
		this.onFilterSelected();

		// Collapse/expand filter on mobile
		this.toggleFilterOnMobile();

	}

	// Collapse/expand filter on mobile by clicking the filter title
	toggleFilterOnMobile() {

		const $filterHeadlines = this.$filter.querySelectorAll('.uc-filter__headline');
		$filterHeadlines.forEach((headline) => {
			const $filterSection = headline.closest('.uc-filter__section');
			headline.addEventListener('click', () => {
				$filterSection.classList.toggle('open');
			});
		});

	}


	// Update the filter count
	updateFilterCount() {

		// get the number of active checkboxes within each uc-filter__section and add the number to the filter headline in brackets
		const $filterSections = this.$filter.querySelectorAll('.uc-filter__section');

		$filterSections.forEach((section) => {
			const $filterCheckboxes = section.querySelectorAll('.filter-checkbox');
			const $filterHeadline = section.querySelector('.uc-filter__headline');
			let count = 0;
			$filterCheckboxes.forEach((checkbox) => {
				if (checkbox.checked) {
					count++;
				}
			});
			if (count > 0) {
				if (!$filterHeadline.innerHTML.includes('(')) {
					// If the headline has no brackets, add them
					$filterHeadline.innerHTML = $filterHeadline.innerHTML + ' (' + count + ')';
				} else {
					// Otherwise replace the number in the brackets
					$filterHeadline.innerHTML = $filterHeadline.innerHTML.replace(/\(\d+\)/, '(' + count + ')');
				}
			} else {
				// Remove the brackets if no filter is selected
				$filterHeadline.innerHTML = $filterHeadline.innerHTML.replace(/\(\d+\)/, '');
			}
		});
	}



	onFilterSelected() {
		this.$filterCheckboxes.forEach((checkbox) => {

			// Check if checkbox state has changed to active
			checkbox.addEventListener('change', () => {
				if (checkbox.checked) {
					// Get filter tag and add it to the filter set
					let filterTag = checkbox.getAttribute('data-filterSource');
					this.filterSet.add(filterTag.trim());
				} else {
					// Get filter tag and remove it from the filter set
					let filterTag = checkbox.getAttribute('data-filterSource');
					this.filterSet.delete(filterTag);
				}

				console.log(this.filterSet);

				// Update the results
				this.updateResults();

				// Update the filter count
				this.updateFilterCount();
			});
		});
	}

	updateResults() {
		// console.log(this.filterSet);

		// If no filters are selected, show all cards
		if (this.filterSet.size === 0) {
			// console.log("show all cards");
			this.$cards.forEach((card) => {
				card.classList.remove('hidden');
			});
		}

		// Show only the cards which data-show attribute matches the filter set
		else {
			this.$cards.forEach((card) => {
				// get the data-show attribute
				let dataShow = card.getAttribute('data-show').split(',');
				// Remove empty '' entries from array
				dataShow = dataShow.filter(function (el) {
					return el != '';
				});

				// All entries in the filterSet must be found in the data-show attribute
				let hitAll = [...this.filterSet].every(x => dataShow.includes(x));

				// if the filter set contains the data-show attribute
				if (hitAll) {
					// show the card
					card.classList.remove('hidden');
				} else {
					// hide the card
					card.classList.add('hidden');
				}


				// // Check if the filterSet has intersection with the data-show attribute
				// let hit = [...this.filterSet].filter(x => dataShow.includes(x));

				// // if the filter set contains the data-show attribute
				// if (hit.length > 0) {
				// 	// show the card
				// 	card.classList.remove('hidden');
				// } else {
				// 	// hide the card
				// 	card.classList.add('hidden');
				// }
			});
		}
	}
}