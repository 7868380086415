export default class PartstogoAboutFeatures {

  constructor(el) {

    this.$numbers = el.querySelectorAll('.partstogo-about-intro__features-number-value');
    this.$numbers.forEach((number) => {
      number.textContent = 0;
    });

    ScrollTrigger.create({
      // markers: true,
      trigger: el,
      start: "top 85%",
      once: true,
      onEnter: () => {
        gsap.fromTo(this.$numbers, {
          textContent: 0,
        }, {
          textContent: (target) => {
            return this.$numbers[target].getAttribute('data-number');
          },
          duration: 2,
          ease: "linear",
          snap: {
            textContent: 1
          },
          stagger: {
            each: 2
          }
        });
      }
    })
  }
}
