export default class WallpaperSlider {

	constructor(c) {

		// Check if there is a slider
		const $slider = c;
		if (!$slider) return;

		const $sliders = $slider.querySelectorAll('.heading__wallpaper-slider');
		$sliders.forEach(slider => {
			// this.initCarpetSlider(slider);

			const $group = slider.querySelector('.heading__wallpaper-group');

			// Copy slider to the end of the wrapper
			slider.appendChild($group.cloneNode(true));
			// slider.appendChild($group.cloneNode(true));

			const $groups = slider.querySelectorAll('.heading__wallpaper-group');
			$groups.forEach(slide => {
				gsap.to(slide, {
					duration: 60,
					yPercent: -100,
					ease: "none",
					repeat: -1
				});
			});

		});

	}

}