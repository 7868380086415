class TechnologySwiper {

	constructor(c) {
		this.init(c);
	}

	init(container) {
		// console.log("init technology swiper: ", container);
		new Swiper(container.querySelector('.technologies-swiper'), {
			slidesPerView: "auto",
			// centeredSlides: true,
			// spaceBetween: 8,
			// breakpoints: {
			// 	320: {
			// 		slidesPerView: 2,
			// 	},
			// 	768: {
			// 		slidesPerView: 3,
			// 	},
			// 	1440: {
			// 		slidesPerView: 3,
			// 	},
			// 	1920: {
			// 		slidesPerView: 4,
			// 	},
			// },
			pagination: {
				el: container.querySelector(".swiper-pagination"),
				type: "fraction",
			},
			navigation: {
				nextEl: container.querySelector(".swiper-button-next"),
				prevEl: container.querySelector(".swiper-button-prev"),
			},
			// on: {
			// 	init: function () {
			// 		console.log('technologies swiper initialized');
			// 	},
			// },
		});
	}
}

export default TechnologySwiper;