export default class EventsList {

	constructor() {

		new List('passed-events', {
			valueNames: ['passed-events__title'],
			page: 6,
			pagination: true
		});

		// Prevent scroll up after link press
		// This is super hacky, works thourh
		setTimeout(() => {
			preventScrollUp();
			checkPaginationCount();
		}, 500);

		function preventScrollUp() {
			const paginationLinks = document.querySelectorAll('.passed-events__pagination a.page');
			paginationLinks.forEach(pl => {
				pl.addEventListener('click', e => {
					e.preventDefault();
					setTimeout(() => {
						preventScrollUp();
					}, 500);
				})
			});
		}

		// Remove pagination if it is only one page
		function checkPaginationCount() {
			const paginationLinks = document.querySelectorAll('.passed-events__pagination a.page');
			if(paginationLinks.length < 2) {
				const pagination = document.querySelector('.passed-events__pagination');
				pagination.remove();
			}
		}
	}
}