export default class StratasysVideos {
    constructor(c) {
        const modalTrigger = c.querySelector(".open-cookie-settings");
        if(!modalTrigger) return;

        // Call cookie consent modal on button click
        modalTrigger.addEventListener("click", function () {
            openCookieBanner();
        });

        // Actually open the banner
        function openCookieBanner() {
            const event = document.createEvent("HTMLEvents");
            event.initEvent("cookies:update", true, false);
            document.body.dispatchEvent(event);
        }

        // Listen for a change in cookie settings -> reload page
        document.body.addEventListener("cookies:saved", function (event) {
            // set local storage item to indicate that the user accepted/declined cookies
            localStorage.setItem("cookiesSeen", true);
            location.reload();
        });
    }
}
