export default class EventCountdown {

	constructor(countdowns) {
		countdowns.forEach(c => {
			this.initCountdown(c);
		});
	}


	initCountdown(c) {

		// Set the date we're counting down to
		const date = new Date(c.getAttribute('data-time'));
		let countDownDate = date.getTime();

		// Get today's date and time
		let now = new Date().getTime();

		// Find the distance between now and the count down date
		let distance = countDownDate - now;

		// Get the DOM objects
		let daysDOM = c.querySelector('.days .value');
		let hoursDOM = c.querySelector('.hours .value');
		let minutesDOM = c.querySelector('.minutes .value');

		// Update the count down every 1 second
		if (distance > 0) {
			let x = setInterval(function () {

				// Get today's date and time
				now = new Date().getTime();

				// Find the distance between now and the count down date
				distance = countDownDate - now;

				// Time calculations for days, hours, minutes and seconds
				let days = Math.floor(distance / (1000 * 60 * 60 * 24));
				let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

				// Display results
				daysDOM.innerHTML = days;
				hoursDOM.innerHTML = hours;
				minutesDOM.innerHTML = minutes;

				// If the count down is finished, write some text
				if (distance < 0) {
					clearInterval(x);
					c.classList.add('expired');
				}


				// Display only if ready
				// if (!c.classList.contains('active') && distance > 0) {
				// 	c.classList.add('active')
				// }
			}, 1000);
		}
	}
}