export default class AnchorButton {

	constructor(btns) {

		const header = document.getElementById('header');
		if (!header) return;
		let offset = 0;
		let scrollspy = document.getElementsByClassName('scrollspy')[0];
		if(!scrollspy) return;
		if (window.innerWidth < 960 && scrollspy) {
			offset = document.getElementsByClassName('scrollspy')[0].offsetHeight + document.getElementsByClassName('scrollspy')[0].offsetHeight;
		} else {
			offset = document.getElementById('header').offsetHeight;
		}

		btns.forEach(btn => {
			btn.addEventListener('click', e => {
				e.preventDefault();
				let targetID = e.target.getAttribute('href');
				let target = document.getElementById(targetID.substring(1));

				gsap.to(window, {
					duration: 1.2,
					scrollTo: {
						y: target.offsetTop - offset,
					}
				});
			})
		});

	}


}