export default class Modal {

  constructor(modal) {

    this.modal = modal;
    this.trigger = document.querySelectorAll(`[data-target="#${modal.id}"`);
    this.content = modal.querySelector('.content');
    this.close = modal.querySelector('.close');
    this.outside = modal.querySelector('.backdrop');

    // GSAP defaults
    gsap.defaults({
      ease: "power2.out",
      duration: 0.4
    });

    // GSAP Timeline
    this.tl = gsap.timeline({
      onStart: () => {
        this.modal.classList.add('active');
        this.modal.ariaHidden = false;
        document.body.classList.add('open-modal');
      },
      onReverseComplete: () => {
        this.modal.classList.remove('active');
        this.modal.ariaHidden = true;
        document.body.classList.remove('open-modal');
      }
    });

    // Animation
    this.tl.from(this.outside, {
      autoAlpha: 0,
    }, "a");
    this.tl.from(this.content, {
      opacity: 0,
      y: "64",
    }, "a+=0.2");
    this.tl.pause(0);

    // Click on open-modal-button
    if (this.trigger) {
      this.trigger.forEach(t => {
        t.addEventListener('click', (e) => {
          e.preventDefault();
          this.openModal();
        });
      })

    }

    // Close on outside click
    if (this.outside) {
      this.outside.addEventListener('click', (e) => {
        this.closeModal();
      });
    }

    // Close on close-button click
    if (this.close) {
      this.close.addEventListener('click', (e) => {
        this.closeModal();
      });
    }
  }

  openModal() {
    this.tl.play();
  }

  closeModal() {
    this.tl.reverse();
  }

}