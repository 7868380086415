export default class Accordion {

	constructor(c) {

		this.accordionsDOM = c.querySelectorAll('.accordion');
		this.accordionsDOM.forEach(a => {
			const toggleButton = a.querySelector('.header');
			toggleButton.addEventListener('click', () => {
				if (a.classList.contains('active')) {
					this.closeAccordion(a);
				} else {
					this.openAccordion(a);
				}
			})
		})

	}



	openAccordion(a) {

		// Close all other accordions first
		let openAccordions = a.closest('.accordions').querySelectorAll('.accordion.active');
		if (openAccordions.length) {
			openAccordions.forEach((oa) => {
				this.closeAccordion(oa);
			});
		}

		// Open new accordion
		a.classList.add('active');

	}



	closeAccordion(a) {
		a.classList.remove('active');
	}

}