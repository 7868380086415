export default class ProductTabCardSlider {

	constructor(c) {
		c.forEach(s => {
			this.init(s);
		});
	}

	init(s) {
		new Swiper(s, {
			spaceBetween: 0,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			loop: true,
			// effect: 'fade',
			on: {
				init: function (e) {
					console.log("ProductTabCardSlider initialized: ", e);
				}
			}
		});
	}
}