export default class AnchorTeaser {

	constructor(c) {

		c.querySelectorAll('.anchor-teaser__button').forEach(b => {
			b.addEventListener('click', e => {
				e.preventDefault();
				let targetID = b.getAttribute('data-target');
				let target = document.getElementById(targetID.substring(1));
				gsap.to(window, {
					duration: 1.2,
					scrollTo: {
						y: target.offsetTop,
					}
				});
			});
		});

	}

}