export default class Headline {

	constructor() {

		if (window.innerWidth > 960) {

			// Create animation timeline
			let
				sidebarHeadline = document.querySelector('.sidebar-headline'),
				moveIt = null;
			// if(document.querySelector('.scrollspy')) moveIt = document.querySelector('.scrollspy');
			// if(document.getElementById('tabs')) moveIt = document.getElementById('tabs');
			// if(document.querySelector('.scrollspies')) moveIt = document.querySelector('.scrollspies');
			if (document.getElementById('sidebar')) moveIt = document.querySelectorAll('#sidebar > *:not(.sidebar-headline)');
			let tl = gsap.timeline();

			tl.to(moveIt, {
				duration: 0.2,
				y: `+=${sidebarHeadline.offsetHeight}`
			});
			tl.to(sidebarHeadline, {
				duration: 0.2,
				y: '0',
				opacity: 1
			})
			tl.pause();

			// Use scrolltrigger to fire animation
			ScrollTrigger.create({
				// markers: true,
				trigger: sidebarHeadline,
				endTrigger: 'html',
				start: `top-=${document.getElementById('header').offsetHeight}`,
				end: "bottom top",
				onEnter: function () {
					tl.play();
				},
				onLeaveBack: function () {
					tl.reverse();
				},
			});

		}
	}
}