export default class PartstogoOctagonEffect {
    constructor(c) {
        this.el = c;
        this.init();
    }

    init() {
        const $trains = this.el.querySelectorAll(".line--train");
        const masterTL = gsap.timeline({ repeat: -1 });

        let delay = 1;
        $trains.forEach(($train) => {
            const tl = gsap.timeline({
                repeat: -1,
                repeatDelay: 1,
            });
            tl.fromTo(
                $train,
                {
                    drawSVG: "0% 0%",
                },
                {
                    duration: 1,
                    drawSVG: "0% 16,67%",
                    ease: "linear",
                }
            );

            tl.fromTo(
                $train,
                {
                    drawSVG: "0% 16,67%",
                },
                {
                    duration: 4,
                    drawSVG: "83,33% 100%",
                    ease: "linear",
                }
            );

            tl.fromTo(
                $train,
                {
                    drawSVG: "83,33% 100%",
                },
                {
                    duration: 1,
                    drawSVG: "100% 100%",
                    ease: "linear",
                }
            );

            delay++;

            tl.progress(1);

            masterTL.add(tl, `a+=${delay}`);
        });

        // Play master timeline at 1.5 speed
        masterTL.timeScale(0.5);
    }

}
