// All switch buttons
const buttons = document.querySelectorAll("#header button.switch");

// Array of menu objects
// let menuObjs = [];
const body = document.body;
const nav = document.getElementById("nav");
let currentTL = null;

// Gsap defaults
gsap.defaults({
	ease: "power2.out",
	duration: 0.4
});

// Collect menu objects
buttons.forEach(button => {

	// Menu animation
	const menu = document.getElementById(button.getAttribute('data-show'));
	let back = null;
	const tl = gsap.timeline({
		onStart: () => {
			menu.style.display = "block";
		},
		onComplete: () => {
			body.classList.add('open-menu');
			button.classList.add('active');
			menu.classList.add('active');
		},
		onReverseComplete: () => {
			button.classList.remove('active');
			menu.classList.remove('active');
			body.classList.remove('open-menu');
		}
	});
	tl.to(menu, {
		x: "0%",
	}, "a");
	tl.pause();

	// Close navigation on Button-Back click
	if (menu) {
		back = menu.querySelector('button.back');
		back.addEventListener('click', (e) => {
			tl.reverse();
		});
	}

	// Open Navigation on Switch click
	if (menu) {
		button.addEventListener('click', (e) => {
			e.preventDefault();

			// If mobile
			if (body.classList.contains('mobile-nav-open')) {
				tl.play();
				currentTL = tl;
			}
			// Else Desktop
			else {
				// Toggle current menu
				if (button.classList.contains('active')) {
					// console.log("close current menu");
					body.classList.remove('open-menu');
					button.classList.remove('active');
					menu.classList.remove('active');
				}
				// Close already open menus before opening a new one
				else if (body.classList.contains('open-menu')) {
					// console.log("close old menu");
					body.classList.remove('open-menu');
					let oldmenu = document.querySelector('.menu.active');
					oldmenu.classList.remove('active');
					let oldbutton = document.querySelector('#nav .switch.active');
					oldbutton.classList.remove('active');
					// console.log("open new menu");
					body.classList.toggle('open-menu');
					button.classList.toggle('active');
					menu.classList.toggle('active');
				} else {
					// console.log("open new menu");
					body.classList.toggle('open-menu');
					button.classList.toggle('active');
					menu.classList.toggle('active');
				}
			}
		});
	}
});


// Close menu on outside click
window.addEventListener('click', function (e) {
	if (body.classList.contains('open-menu')) {
		let menu = document.querySelector('.menu.active');
		if (!menu.contains(e.target) && !(e.target.parentElement.classList.contains('switch') || e.target.classList.contains('switch'))) {
			body.classList.remove('open-menu');
			menu.classList.remove('active');
			let button = document.querySelector('#header .switch.active');
			button.classList.remove('active');
		}
	}
});


// Close menu if main navigation is closed
document.addEventListener('PTG--closeNavigation', (e) => {
	// console.log("testEvent received: ", e);
	console.log(currentTL);
	if(currentTL) {
		currentTL.reverse();
	}
});