import { rotate } from "three/examples/jsm/nodes/Nodes.js";

export default class PartsToGoIntro {
    constructor(c) {
        this.el = c;
        // this.enterAnimations();
        this.scrollAnimation();
    }

    scrollAnimation() {
        gsap.registerPlugin(ScrollTrigger);

        const section = this.el;
        const boxesContainer = this.el.querySelector(".partstogo-intro__boxes");
        const boxes = this.el.querySelectorAll(".partstogo-intro__box");
        const video = this.el.querySelector(".partstogo-intro__video");
        const label = this.el.querySelector(".partstogo-intro__label");

        const mm = gsap.matchMedia();

        // Desktop timeline
        mm.add("(min-width: 1200px)", () => {
            console.log("desktop TL");
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: this.el,
                    start: "top top",
                    end: "bottom 100vh",
                    pin: true,
                    scrub: true,
                    // markers: true,
                },
            });
            tl.to(
                boxesContainer,
                {
                    y: "-=90vh",
                    ease: "linear",
                },  
                "a"
            );
            // tl.from(
            //     boxes,
            //     {
            //         // opacity: 0.25,
            //         yPercent: (i) => (i % 2 === 0 ? 0 : 100),
            //         ease: "quad.out",
            //     },  
            //     "a"
            // );
            tl.to(
                video,
                {
                    opacity: 0.1,
                    ease: "quad.out",
                },
                "a"
            );
        });

        // Mobile timeline
        // mm.add("(max-width: 1199px)", () => {
        //     console.log("mobile TL");
        //     const tl = gsap.timeline({
        //         scrollTrigger: {
        //             startTrigger: section,
        //             trigger: video,
        //             start: "top top",
        //             end: () => {
        //                 // Calculate the end position dynamically
        //                 console.log(
        //                     section.getBoundingClientRect().bottom -
        //                         window.innerHeight
        //                 );
        //                 return (
        //                     section.getBoundingClientRect().bottom -
        //                     window.innerHeight
        //                 );
        //             },
        //             pin: true,
        //             scrub: true,
        //             pinSpacing: false,
        //             invalidateOnRefresh: true,
        //             markers: true,
        //             onInit: () => {
        //                 console.log("init");
        //             },
        //             onEnter: () => {
        //                 console.log("pin the video");
        //             },
        //             onLeave: () => {
        //                 console.log("unpin the video");
        //             },
        //         },
        //     });

        //     // gsap.to(label, {
        //     //     // y: "0",
        //     //     scrollTrigger: {
        //     //         trigger: section,
        //     //         start: "top top",
        //     //         end: () => {
        //     //             // Calculate the end position dynamically
        //     //             console.log(
        //     //                 section.getBoundingClientRect().bottom -
        //     //                     window.innerHeight
        //     //             );
        //     //             return (
        //     //                 section.getBoundingClientRect().bottom
        //     //             );
        //     //         },
        //     //         pin: true,
        //     //         scrub: 0.5,
        //     //         pinSpacing: false,
        //     //         markers: true,
        //     //         onInit: () => {
        //     //             console.log("init");
        //     //         },
        //     //         onEnter: () => {
        //     //             console.log("pin the video");
        //     //         },
        //     //         onLeave: () => {
        //     //             console.log("unpin the video");
        //     //         },
        //     //     },
        //     // });
        // });
    }

    enterAnimations() {
        // Make a entrance animation with gsap
        const tl = gsap.timeline();
        tl.set(this.el.querySelector(".partstogo-intro__label"), {
            opacity: 1,
        });
        tl.from(this.el.querySelectorAll(".partstogo-intro__label > *"), {
            duration: 0.6,
            y: 8,
            opacity: 0,
            ease: "power4.out",
            stagger: 0.1,
        });
        // tl.from(
        //     this.el.querySelectorAll(".partstogo-intro__background-image"),
        //     {
        //         duration: 0.6,
        //         y: 8,
        //         opacity: 0,
        //         ease: "power4.out",
        //         stagger: 0.1,
        //     }
        // );
        tl.from(this.el.querySelector(".partstogo-intro__logos"), {
            duration: 0.6,
            y: 8,
            opacity: 0,
            ease: "power4.out",
        });
    }
}
