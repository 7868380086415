export default class PartsToGoNavDropdown {

	constructor(c) {
		this.el = c;
		this.trigger = this.el.querySelector('.partstogo__submenu-trigger');
		this.submenu = this.el.querySelector('.partstogo__submenu');
		this.init();
	}

	init() {
		// Toggle menu on click
		this.trigger.addEventListener('click', () => {
			this.toggle();
		});
		
		// Close submenu on click outside
		document.addEventListener('click', (e) => {
			if (!this.el.contains(e.target)) {
				this.el.classList.remove('active');
				this.submenu.classList.remove('active');
			}
		});
	}

	toggle() {
		this.el.classList.toggle('active');
		this.submenu.classList.toggle('active');
	}
}