export default class Cookies {

  constructor(c) {

    // Listen for a change in cookie settings -> reload page
    const $body = document.body;
    $body.addEventListener('cookies:saved', function (event) {
      // set local storage item to indicate that the user accepted/declined cookies
      localStorage.setItem('cookiesSeen', true);
      location.reload();
    })

    // Call cookie consent modal on button click
    c.addEventListener('click', function () {
      openCookieBanner();
    });

    // Open cookie banners with custom buttons as well
    let openCookiesButtons = document.querySelectorAll('.open-cookie-settings');
    openCookiesButtons.forEach(b => {
      b.addEventListener('click', function () {
        openCookieBanner();
      });
    });

    // Actually open the banner
    function openCookieBanner() {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('cookies:update', true, false);
      $body.dispatchEvent(event);
    }

  }
}