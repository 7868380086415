export default class ShopFilter {

  constructor(c) {

    this.container = c;
    this.filterDetails = {};
    this.filter = {};
    // this.featured = document.querySelector('.filter-list').getAttribute('data-featured').split(",").filter(x => x !== "");
    this.categoryButtons = document.querySelectorAll('.filter-category-button');
    this.filterButtons = document.querySelectorAll('.filter-button');
    this.noResults = document.querySelector('.no-results');
    this.results = document.querySelectorAll('.results .card');
    this.fetchController;
    this.containerHeight = 0;

    // Open/close categories
    this.categoryButtons.forEach((b) => {
      b.addEventListener('click', (e) => {
        let toggle = e.currentTarget;
        if (toggle.classList.contains('active')) {
          toggle.classList.remove('active');
        } else {
          let listItems = [...toggle.closest('ul').children];
          listItems.forEach((toggle) => {
            toggle.classList.remove('active');
          });
          toggle.classList.add('active');
        }
      })
    });

    // Listen for filter buttons
    this.filterButtons.forEach((b) => {
      b.addEventListener('click', (e) => {

        // Abort all ongoing fetches
        if (this.fetchController) {
          this.fetchController.abort();
        }

        let el = e.currentTarget;
        let cb = el.closest('.filter-dropdown').previousElementSibling;
        let cbText = cb.querySelector('.text');
        // Deactivate all other filter-buttons
        el.closest('ul').querySelector('.filter-button.active').classList.remove('active');
        // Activate this filter-button
        el.classList.add('active');
        // Close dropdown
        cb.classList.remove('active');
        // Set Category button text
        cbText.innerText = el.innerText;
        // Update the filter
        this.updateFilter();
      })
    });

    // Close filter on outside click
    window.addEventListener('click', function (e) {
      let filterDropdown = c.querySelector('.filter-category-button.active');
      if (filterDropdown) {
        if (!filterDropdown.contains(e.target) && !(e.target.parentElement.classList.contains('filter-category-button') || e.target.classList.contains('filter-category-button'))) {
          filterDropdown.classList.remove('active');
        }
      }
    });

    // Update filter initialy
    this.updateFilter();

  }



  // Calculate which products to show
  // Results in an updated "filter" array
  updateFilter() {

    // Create filterDetails object
    this.categoryButtons.forEach(cb => {
      let category = cb.getAttribute('data-category');
      let activeFilterButton = cb.parentElement.querySelector('.filter-button.active');
      if (activeFilterButton) {
        this.filterDetails[category] = activeFilterButton.getAttribute('data-filter').split(",").filter(x => x !== "");
      }
    });

    // Merge all filter arrays in one array
    let merged = [];
    for (let products in this.filterDetails) {
      merged = merged.concat(this.filterDetails[products]);
    }

    // Save all products that appear more than once (intersecting) into the filter array
    let filter = [];
    merged.forEach(v => {
      if (merged.filter(x => x === v).length > 1 && !filter.includes(v)) {
        filter.push(v);
      }
    });

    // Sort products starting with featured (Array) products
    // let featuredArray = this.featured;
    // filter.sort((a, b) => featuredArray.indexOf(a) - featuredArray.indexOf(b)).reverse();

    // Final filter array
    this.filter = filter;

    // Show "no results" text if needed be
    if (!this.filter.length) {
      this.noResults.classList.add('active');
    } else {
      this.noResults.classList.remove('active');
    }

    // Now update results (slides)
    this.updateResults();

  }



  // Handle the slider slides based on the filter array
  updateResults() {

    this.results.forEach(r => {
      let hit = this.filter.includes(r.getAttribute('data-id'));
      hit ? r.parentElement.classList.add('active') : r.parentElement.classList.remove('active');
    });

  }

}