export default class StratasysScrollspy {
    constructor(c) {
        this.highlightScrollspy(c);
        this.setupResizeListener();
    }

    highlightScrollspy(scrollspy) {
        const sections = document.querySelectorAll(
            "#content .scrollspy-target"
        );
        const sectionsArray = gsap.utils.toArray(sections);

        if (sections.length) {
            sectionsArray.forEach((s) => {
                ScrollTrigger.create({
                    trigger: s,
                    // markers: true,
                    start: `top 50%`,
                    end: "bottom 50%",
                    onEnter: function () {
                        let button = scrollspy.querySelector(
                            `a[href="#${s.id}"]`
                        );
                        if (button) button.classList.add("active");
                    },
                    onLeave: function () {
                        let button = scrollspy.querySelector(
                            `a[href="#${s.id}"]`
                        );
                        if (button) button.classList.remove("active");
                    },
                    onEnterBack: function () {
                        let button = scrollspy.querySelector(
                            `a[href="#${s.id}"]`
                        );
                        if (button) button.classList.add("active");
                    },
                    onLeaveBack: function () {
                        let button = scrollspy.querySelector(
                            `a[href="#${s.id}"]`
                        );
                        if (button) button.classList.remove("active");
                    },
                });
            });
        }
    }

    setupResizeListener() {
        window.addEventListener("resize", () => {
            ScrollTrigger.refresh();
			console.log("refreshed");
        });
    }
}
