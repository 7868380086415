export default class Download {

	constructor(c) {

		this.$image = c.querySelector('.download__image');

		
		// initialize ukiyo if viewport is larger than 1600px
		if (window.innerWidth > 1600 && this.$image) {

			new Ukiyo(this.$image, {
				speed: 1.1, // 1~2 is recommended
				willChange: true, // This may not be valid in all cases
			})
		}
	}
}